import { saveTokenToLocalStorage } from './helpers';
import { PLAN_USER_DATA_UPDATES } from '../utils/variables';
import Auth from '@aws-amplify/auth';

// Moved here from AuthProvider, as Cypress is importing login() for tests
import config from '../utils/aws/awsExports';
import Amplify from 'aws-amplify';

import { userService } from './ServiceClient';

Amplify.configure({
    ...config
});

/**
 * Create a user object from cognito user attributes and internal user data
 * @param cognitoUser
 * @returns {Promise<*>}
 */
export async function createUserObject(cognitoUser) {
    const userProfileObject = {};
    // loop through the userAttributes and append to `userProfileObject` as attributes
    const attributes = cognitoUser.attributes;

    if (attributes) {
        for (const [key, value] of Object.entries(attributes)) {
            // custom Cognito attributes will be prefixed with `custom:`, so we must strip away that from the string
            if (key.indexOf('custom:') >= 0) {
                const name = key.slice(7, key.length);
                userProfileObject[name] = value;
            } else {
                // normal Cognito attributes will not be prefixed with `custom:` so we can use use the string immediately
                userProfileObject[key] = value;
            }
        }
    }

    userProfileObject.username = cognitoUser.username;

    // fetch the purchases data
    let userPurchases = {};
    userPurchases = await getUserData(cognitoUser);
    const user = Object.assign(userProfileObject, userPurchases);
    return transformUserData(user);
}

/**
 * Method to get a User Data via SSR call
 * @param cognitoUser
 * @returns {Promise<unknown>}
 */
async function getUserData(cognitoUser) {
    try {
        return userService.get(`user/${cognitoUser.username}?tz=${new Date().getTime()}`, {
            token: `${cognitoUser.getSignInUserSession().getIdToken().getJwtToken()}`
        });
    } catch (err) {
        console.log(err);
        return {};
    }
}

/**
 * Prepare internal user data object
 * @param userData
 * @returns {*}
 */
function transformUserData(userData) {
    // set account for shared data. Most of the users have the same ID and same shared data. Tem members get data from their admin
    userData.sharedDataUsername =
        userData.teams && userData.teams[0] ? userData.teams[0] : userData.username;

    // in case user has a legacy bundles param or is premium without being an subscriber
    if (
        (userData.isPremium || userData.bundle) &&
        !userData.isTrial &&
        !userData.subscriptionId &&
        !userData.planId
    ) {
        userData.isPremium = false;
        userData.isSingleAppUser = true;
    }

    return userData;
}

/**
 * Sign in a user and return cognito user object
 * @param email
 * @param password
 * @returns {Promise<any>}
 */
export async function login(email, password) {
    const cognitoUser = await Auth.signIn(email, password);
    return cognitoUser;
}

/**
 * Helper method to sign up a user
 * @param email
 * @param name
 * @param password
 * @returns {Promise<unknown>}
 */
export async function signUp(email, name, password) {
    await Auth.signUp({
        username: email,
        password,
        attributes: {
            email
        }
    });
}

/**
 * Extends user trial as part of partnership
 */
export async function activatePartnership(partnershipId) {
    try {
        await userService.post(`partnership`, {
            partnershipId
        });
        return await getCurrentUser();
    } catch (err) {
        throw new Error(err);
    }
}

/**
 * Extends user trial as part of partnership
 */
export async function getPartnership(partnershipId) {
    return userService.get(`partnership/${partnershipId}`);
}

/**
 * Get a current auth user object
 * and refresh token
 * @returns {Promise<null|*>}
 */
export async function getCurrentUser() {
    // use bypassCache to load the user from API, avoiding localStorage
    const currentAuthUser = await Auth.currentAuthenticatedUser({
        bypassCache: true
    });
    if (currentAuthUser) {
        const session = await Auth.userSession(currentAuthUser);
        const user = await createUserObject(currentAuthUser);
        if (session.isValid()) {
            saveTokenToLocalStorage(session.getIdToken().getJwtToken());
        }
        return user;
    }
    return null;
}

/**
 * Used in getUserPayments action in authActions
 * @returns {Promise<*[]>}
 */
export async function getUserPaymentsApi() {
    try {
        return await userService.get(`payments`);
    } catch (err) {
        console.log(err);
        return [];
    }
}

/**
 *
 * @param {string} name - name of the attribute to change
 * @param {string} value - value
 * @param {bool} isCustomif it's default cognito attribute or custom
 * @returns {Promise}
 */
export async function updateUserAttribute(name, value, isCustom) {
    const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true
    });

    if (cognitoUser !== null) {
        const attributeList = [];
        const attrName = isCustom ? `custom:${name}` : name;
        attributeList[attrName] = value;

        try {
            const result = await Auth.updateUserAttributes(cognitoUser, attributeList);
            return result;
        } catch (err) {
            return err;
        }
    } else {
        return null;
    }
}

export async function cancelPlan() {
    try {
        await userService.post(`cancel`);
        const user = await getCurrentUser();
        return user;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export async function accountDeleteRequest(accessToken) {
    return userService.post('account-delete-request', { accessToken });
}

export async function accountDeleteConfirm(accessToken, code) {
    return userService.delete('account-delete-confirm', {
        headers: {
            'X-token': accessToken,
            'X-code': code
        }
    });
}

// to preserve a good user experience, we'll unlocked the user's plan & features client side
// before waiting for remote webhook to process. We basically get the fresh user object and enrich it with
// plan data, for a plan that user just purchased.
// There is just one catch - the returned data from Paddle's Checkout.js don't have all the subscription info we store
// - nextBillDate & subscritionUpdateUrl are missing. So after purchase callback we add all data we need
// and then imn success dialog we try to get the rest

export async function getUserAfterPlanChange(planId, checkoutId) {
    const user = await getCurrentUser();

    const updatedData = PLAN_USER_DATA_UPDATES[planId.toString()];

    const updatedUser = Object.assign(user, updatedData);

    // add paddle subscription data
    updatedUser.subscriptionId = checkoutId;
    updatedUser.planId = planId.toString();

    // remove trial expired flag
    if (updatedUser.trialExpire) {
        delete updatedUser.trialExpire;
    }

    // remove plan expired flag
    if (updatedUser.planExpire) {
        delete updatedUser.planExpire;
    }

    // set trial to false
    if (updatedUser.isTrial) {
        updatedUser.isTrial = false;
    }

    // hide trial modal
    if (updatedUser.isTrialEndModal) {
        updatedUser.isTrialEndModal = false;
    }

    // add premium bundle
    if (!updatedUser.isPremium) {
        updatedUser.isPremium = true;
    }

    return transformUserData(updatedUser);
}

/**
 * Changes a Cognito user password
 * @param {string} name - name of the attribute to change
 * @param {string} value - value
 * @returns {Promise}
 */
export async function changePassword(oldPassword, newPassword) {
    const user = await Auth.currentAuthenticatedUser();
    try {
        const data = await Auth.changePassword(user, oldPassword, newPassword);
        console.log('changePassword: ', data);
        return true;
    } catch (err) {
        console.log(err);
        return err;
    }
}

export async function hideTrialModalApi(username) {
    return userService.post(`hide-trial`, { username });
}
