import { isCI, isServer } from './envDetection';
import {
    brazeInit,
    brazeChangeUser,
    brazeEntriesProperties,
    brazeCustomEvent,
    brazeCustomUserAttribute
} from './braze';
import { v4 as uuidv4 } from 'uuid';

const amplitude = isServer() ? null : require('amplitude-js');

const AMPLITUDE_API_KEY = '3ba838544a9e981559bc2428c1d5c367';

export const storeAmplitudeDeviceId = () => {
    const deviceId = amplitude.getInstance().options.deviceId;
    localStorage.setItem('amplitude_deviceId', deviceId);
};

const getBrazeEvent = (eventObject) => {
    const res = { ...eventObject };

    for (const [key, value] of Object.entries(res)) {
        if (!value || Array.isArray(value) || typeof value === 'object') {
            delete res[key];
        }
    }

    return res;
};

export const initTrackingParty = () => {
    if (isServer() || isCI()) return;

    let options = {
        includeUtm: true,
        includeReferrer: true,
        deviceId: uuidv4()
    };
    // check if deviceId has been stored and use it for ongoing new instance to merge with previous
    // usecase: federated sign-up
    const deviceId = localStorage.getItem('amplitude_deviceId');
    if (deviceId) {
        options = { ...options, deviceId };
        localStorage.removeItem('amplitude_deviceId');
    }

    amplitude.getInstance().init(AMPLITUDE_API_KEY, null, options);
};

export const setTrackUserId = (username) => {
    if (isServer() || window.Cypress) return;
    amplitude.getInstance().setUserId(username);

    brazeInit();
    brazeChangeUser(username);
};

export const setTrackUserProperties = (properties) => {
    if (isServer()) return;
    amplitude.getInstance().setUserProperties(properties);

    brazeEntriesProperties(properties);
};

export const trackEvent = (eventType, eventProperties, mockup) => {
    if (isServer() || isCI()) return;
    const properties = { ...eventProperties };

    if (mockup) {
        Object.assign(properties, createTrackMockupData(mockup));
    }
    amplitude.getInstance().logEvent(eventType, properties);

    brazeCustomEvent(eventType, getBrazeEvent(properties));
};

export const unsetAplitudeUser = () => {
    if (isServer()) return;
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().regenerateDeviceId();
};

export const addTrackUserProperties = (property, value) => {
    if (isServer()) return;
    const identify = new amplitude.Identify().add(property, value);
    amplitude.getInstance().identify(identify);

    brazeCustomUserAttribute(property, value);
};

export const createTrackMockupData = (mockup) => {
    return {
        MockupId: mockup.id ? mockup.id : '',
        MockupShortId: mockup.shortId ? mockup.shortId : '',
        MockupTitle: mockup.title ? mockup.title : '',
        MockupCategory: mockup.category ? mockup.category : '',
        MockupCollections: mockup.collections ? mockup.collections : '',
        MockupBundle: mockup.isPremium ? 'premium' : 'free', // should be removed completely in future
        MockupIsPremium: mockup.isPremium,
        MockupHasBackground: mockup.hasBackground,
        MockupHasVariations: mockup.assets && mockup.assets.length > 1,
        MockupHasColorMask: mockup.hasOwnProperty('colorMask'),
        MockupAuthor: mockup.author
    };
};
