import {
    ADD_BG_IMAGES,
    ADD_HISTORY,
    ADD_UPLOADED_VIDEO_TO_SCREEN,
    CHANGE_SELECTED_SCREEN,
    EMPTY_MOCKUP,
    FETCH_AVAILABLE_SIZES,
    GET_HISTORY,
    HIGHLIGHT_SCREEN,
    SAVE_SCREEN,
    SET_AUTHOR,
    SET_BACKGROUND,
    SET_BG_IMAGES,
    SET_CANVAS_CROPPER,
    SET_CANVAS_CROPPER_AREA,
    SET_COLOR_MASK_COLOR,
    SET_COLOR_PALETTE,
    SET_CONTROL_SECTION_TAB,
    SET_CROPPER,
    SET_DOF,
    SET_FILEPICKER,
    SET_LOGO_SHOW,
    SET_SHADOWS_SHOW,
    SET_MOCKUP,
    SET_MOCKUP_KEEP_SCREEN,
    SET_MOCKUP_LOADING,
    SET_SIMILAR_MOCKUPS,
    SET_BRANDING_WATERMARK,
    SET_VARIATION,
    UPLOAD_HISTORY_SCREENS,
    SET_LIVE_PREVIEW_TO_MOCKUP,
    FETCH_MOCKUP_CLIENT_SIDE,
    SET_MOCKUP_DETAIL,
    SET_DIRTY,
    SET_DIRTY_TSHIRT_COLOR,
    SET_DIRTY_SCREEN,
    SET_IS_INITIAL_STATE,
    SET_IS_DOWNLOADED_LAST_MOCKUP,
    SET_THUMB,
    SET_MOCKUP_IS_INITIALIZED,
    SET_IS_FORBIDDEN_LEAVE_ALERT
} from '../actions/actionTypes';
import {
    createEmptyScreenArrayForMockup,
    createColorMaskColorsForMockup
} from '../utils/mockupDetail/mockupDetailHelper';

const INITIAL_STATE = {
    mockup: {},
    screens: [],
    bg: [255, 255, 255, 1], // Array Number || Obj {blur: number, image: base64img}
    variation: 0, // color variants
    currentSelectedScreen: 0,
    imageCropper: null,
    highlightedScreen: null,
    isMockupLoading: true,
    dof: false,
    canvasCropper: null,
    canvasCropperArea: null,
    author: null,
    availableSizes: [],
    bgImages: [],
    colorMaskColors: [],
    colorPalettes: [],
    showLogo: false,
    showShadows: true,
    controlSectionTab: 'images',
    similarMockups: [],
    brandingWatermark: null,
    isDirtyScreenCounter: 0,
    isDirty: 0,
    isDirtyTshirtColor: false,
    isHistoryLoading: false,
    isLivePreviewLoading: false,
    isVideo: false,
    filepicker: null,
    isInitializedMockup: false,
    isSavedMockup: false,
    isInitialState: false,
    isMissingHistory: false,
    isDownloadedLastMockup: false,
    thumb: '',
    isForbiddenLeaveAlert: false
};

export default function mockupDetailReducer(state = INITIAL_STATE, action) {
    let update;
    switch (action.type) {
        case SET_THUMB: {
            return {
                ...state,
                thumb: action.payload
            };
        }
        case SET_IS_FORBIDDEN_LEAVE_ALERT:
            return {
                ...state,
                isForbiddenLeaveAlert: action.payload
            };
        case SET_MOCKUP_IS_INITIALIZED:
            return {
                ...state,
                isInitializedMockup: action.payload
            };
        case SET_IS_DOWNLOADED_LAST_MOCKUP:
            return {
                ...state,
                isDownloadedLastMockup: action.payload
            };
        case SET_IS_INITIAL_STATE:
            return {
                ...state,
                isInitialState: action.payload
            };
        case SET_DIRTY:
            return {
                ...state,
                isDirty: action.payload
            };
        case SET_DIRTY_TSHIRT_COLOR:
            return {
                ...state,
                isDirtyTshirtColor: action.payload
            };
        case SET_DIRTY_SCREEN:
            return {
                ...state,
                isDirtyScreenCounter: action.payload
            };

        case SAVE_SCREEN:
            return {
                ...state,
                screens: updateSavedScreens(action.payload, state),
                highlightedScreen: null,
                isVideo: isVideoInScreens(updateSavedScreens(action.payload, state)),
                bg: updateBgAfterScreenLoad(state.bg, action.payload),
                isDirtyScreenCounter: state.isDirtyScreenCounter + 1
            };

        case `${SET_MOCKUP}_PENDING`:
            return {
                ...state,
                isInitializingMockup: true
            };
        case SET_MOCKUP:
        case `${SET_MOCKUP}_FULFILLED`:
            update = { ...INITIAL_STATE };
            update.mockup = action.payload.data ? action.payload.data : action.payload;
            update.screens =
                state.screens && state.screens.length
                    ? [...state.screens]
                    : createEmptyScreenArrayForMockup(update.mockup);
            update.colorMaskColors = createColorMaskColorsForMockup(update.mockup);
            // prevent loader for being triggered for the second time in case a mockup is loaded twice
            // this scenario can happen for internal dev accounts

            /* TODO maybe wrong?
            if (state.mockup.id && state.mockup.id === update.mockup.id) {
                update.isMockupLoading = false;
            }
             */

            return {
                ...state,
                ...update,
                isHistoryLoading: state.isHistoryLoading
            };
        case SET_MOCKUP_DETAIL:
            return {
                ...state,
                ...action.payload,
                isMockupLoading: false,
                isDirtyScreenCounter: state.isDirtyScreenCounter + 1
            };
        case SET_MOCKUP_KEEP_SCREEN:
            return { ...state, ...action.payload };

        case EMPTY_MOCKUP:
            return INITIAL_STATE;

        case `${FETCH_MOCKUP_CLIENT_SIDE}_FULFILLED`:
            return { ...state, mockup: action.payload };

        case `${SET_AUTHOR}_FULFILLED`:
            return { ...state, author: action.payload };

        case CHANGE_SELECTED_SCREEN:
            return { ...state, currentSelectedScreen: action.payload };

        case SET_CROPPER:
            return { ...state, imageCropper: action.payload };

        case SET_BACKGROUND:
            return {
                ...state,
                bg: mergeBg(state.bg, action.payload)
            };
        case `${SET_BACKGROUND}_FULFILLED`:
            return {
                ...state,
                bg: mergeBg(state.bg, action.payload)
            };

        case HIGHLIGHT_SCREEN:
            return { ...state, highlightedScreen: action.payload };

        case SET_MOCKUP_LOADING:
            return { ...state, isMockupLoading: action.payload };
        case SET_DOF:
            return { ...state, dof: action.payload };
        case SET_VARIATION:
            return {
                ...state,
                variation: action.payload,
                isMockupLoading: true
            };
        case SET_CANVAS_CROPPER:
            return {
                ...state,
                canvasCropper: action.payload
            };

        case SET_CANVAS_CROPPER_AREA:
            return {
                ...state,
                canvasCropperArea: action.payload
            };

        case `${FETCH_AVAILABLE_SIZES}_FULFILLED`:
            return { ...state, availableSizes: action.payload.data };
        case `${ADD_BG_IMAGES}_FULFILLED`:
            return {
                ...state,
                bgImages: [...state.bgImages, ...action.payload]
            };

        case SET_BG_IMAGES:
            return {
                ...state,
                bgImages: action.payload
            };

        case `${SET_BG_IMAGES}_FULFILLED`:
            return {
                ...state,
                bgImages: action.payload
            };
        case SET_COLOR_MASK_COLOR:
            return {
                ...state,
                colorMaskColors: updateColorMaskColors(
                    state.colorMaskColors,
                    action.payload.index,
                    action.payload.color
                )
            };
        case SET_COLOR_PALETTE:
            return {
                ...state,
                colorPalettes: updateColorPalettes(
                    state.colorPalettes,
                    action.payload.index,
                    action.payload.colorPalette
                )
            };
        case SET_LOGO_SHOW:
            return {
                ...state,
                showLogo: action.payload
            };
        case SET_SHADOWS_SHOW:
            return {
                ...state,
                showShadows: action.payload
            };
        case SET_CONTROL_SECTION_TAB:
            return { ...state, controlSectionTab: action.payload };
        case `${SET_SIMILAR_MOCKUPS}_FULFILLED`:
            return { ...state, similarMockups: action.payload };

        case SET_BRANDING_WATERMARK:
            return {
                ...state,
                brandingWatermark: action.payload
            };
        case `${UPLOAD_HISTORY_SCREENS}_FULFILLED`:
            return {
                ...state,
                screens: mergeScreensWithHistoryUpload(action.payload, state.screens)
            };
        case `${ADD_HISTORY}_FULFILLED`:
            return {
                ...state
            };
        case `${GET_HISTORY}_PENDING`:
            return {
                ...state,
                isHistoryLoading: true
            };
        case `${GET_HISTORY}_FULFILLED`:
            return {
                ...state,
                ...action.payload,
                canvasCropperArea:
                    action.payload.canvasCropperArea?.x !== undefined
                        ? { ...action.payload.canvasCropperArea, isInit: true }
                        : null,
                colorMaskColors: sanitizeHistoryColorMasks(action.payload, state.mockup),
                isVideo: isVideoInScreens(action.payload.screens),
                isHistoryLoading: false,
                isSavedMockup: true,
                isMissingHistory: false
            };
        case `${GET_HISTORY}_REJECTED`:
            return {
                ...state,
                isMissingHistory: true,
                isHistoryLoading: false
            };
        case ADD_UPLOADED_VIDEO_TO_SCREEN:
            return {
                ...state,
                screens: mergeScreensWithVideoUpload(action.payload, state.screens)
            };
        case SET_FILEPICKER:
            return { ...state, filepicker: action.payload };
        case `${SET_LIVE_PREVIEW_TO_MOCKUP}_PENDING`:
            return { ...state, isLivePreviewLoading: true };
        case `${SET_LIVE_PREVIEW_TO_MOCKUP}_REJECTED`:
            return { ...state, isLivePreviewLoading: false };
        case `${SET_LIVE_PREVIEW_TO_MOCKUP}_FULFILLED`:
            return {
                ...state,
                ...action.payload,
                isLivePreviewLoading: false
                // TODO fix a glitch with history save trigger for livepreview
                //  for now disabled history
                // isDirty: state.isDirty + 1
            };
        default:
            return state;
    }
}
// background can be either Array (color values) or a object (for bg image)
// if we're replacing object with array or other way round, the new value is pasted.
// if we're replacing object with another object - it means we're updating some part of bg image we perform a deep merge
function mergeBg(old, update) {
    if (Array.isArray(old) || Array.isArray(update)) {
        return update;
    }
    const newObject = { ...old };

    return Object.assign(newObject, update);
}

function sanitizeHistoryColorMasks(historyItem, mockup) {
    const defaultMasks = createColorMaskColorsForMockup(mockup);
    if (!historyItem) {
        return defaultMasks;
    }

    if (historyItem.colorMaskColor && !historyItem.colorMaskColors) {
        return [historyItem.colorMaskColor];
    }

    if (historyItem.colorMaskColors && historyItem.colorMaskColors.length !== defaultMasks.length) {
        return defaultMasks;
    }
    return historyItem.colorMaskColors;
}

function updateSavedScreens(newScreen, state) {
    const update = [...state.screens];
    update[state.currentSelectedScreen] = newScreen;

    for (const [index, screen] of state.mockup.screens.entries()) {
        if (screen.mirror === state.currentSelectedScreen) {
            update[index] = newScreen;
        }
    }

    return update;
}

function mergeScreensWithHistoryUpload(history, currentScreens) {
    const update = [...currentScreens];

    if (!history) {
        return update;
    }

    for (const [index, uploadedScreen] of history.entries()) {
        if (uploadedScreen && uploadedScreen.historyFilename && update[index]) {
            update[index].historyFilename = uploadedScreen.historyFilename;
        }

        if (uploadedScreen && uploadedScreen.uploadedVideo && update[index]) {
            update[index].uploadedVideo = uploadedScreen.uploadedVideo;
        }
    }
    return update;
}

function mergeScreensWithVideoUpload(videoUpload, currentScreens) {
    const update = [...currentScreens];

    if (!videoUpload) {
        return update;
    }

    update[videoUpload.index].uploadedVideo = videoUpload.fileUrl;

    return update;
}

function isVideoInScreens(screens) {
    let isVideo = false;
    for (let i = 0; i < screens.length; i++) {
        if (isVideoScreen(screens[i])) {
            isVideo = true;
            break;
        }
    }
    return isVideo;
}

/**
 * Helper function that checks user uploaded screens and finds if some of them contains video
 * @param screen
 * @returns {*|boolean|boolean}
 */
function isVideoScreen(screen) {
    if (!screen) return false;
    return screen.hasOwnProperty('uploadedVideo');
}

/**
 * In most cases we're not touching the current background after screen load,
 * but when the background has some sort of transparency and video is loaded, we had to remove it
 * @param currentBg
 * @param screen
 */
function updateBgAfterScreenLoad(currentBg, screen) {
    if (!Array.isArray(currentBg)) return currentBg;
    if (currentBg[3] === 1) return currentBg;

    const isMockupVideo = isVideoScreen(screen);

    if (!isMockupVideo) return currentBg;

    const update = [...currentBg];
    update[3] = 1;
    return update;
}

function updateColorMaskColors(currentColors, index, newColor) {
    const res = [...currentColors];
    res[index] = newColor;
    return res;
}

function updateColorPalettes(currentColorPalettes, index, newPalette) {
    const res = [...currentColorPalettes];
    res[index] = newPalette;
    return res;
}
