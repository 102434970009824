import {isServer, isCI} from "./envDetection";

const appboy =  isServer() ? null : require("@braze/web-sdk");

let checkBrazeInit;

export const brazeInit = () => {
    if (isServer() || isCI()) return;
    appboy.initialize('ac4e6df7-07b2-4f58-8c92-cf2aebd466a0', {
        baseUrl: "sdk.iad-01.braze.com"
    });

    checkBrazeInit = true;
}

export const brazeChangeUser = (user) => {
    if (checkBrazeInit) {
        appboy.changeUser(user);
    }
}

export const brazeEntriesProperties = (properties) => {
    if (checkBrazeInit) {
        for (const [key, value] of Object.entries(properties)) {
            if (key !== "email" && key !== "firstName" && key !== "lastName") {
                appboy.getUser().setCustomUserAttribute(key, value);
            }
        }
    }
}

export const brazeCustomEvent = (eventType, brazeEvent) => {
    if (checkBrazeInit) {
        appboy.logCustomEvent(eventType, brazeEvent);
    }
}

export const brazeCustomUserAttribute = (property, value) => {
    if (checkBrazeInit) {
        appboy.getUser().setCustomUserAttribute(property, value);
    }
}

export const brazeCachedCards = () => {
    if (checkBrazeInit) {
        return appboy.getCachedContentCards().getUnviewedCardCount();
    }
}

export const brazeRefreshCards = () => {
    if (checkBrazeInit) {
        appboy.requestContentCardsRefresh();
    }
}

export const brazeToggleCards = (element) => {
    if (checkBrazeInit && element) {
        appboy.display.toggleContentCards(element);

        setTimeout(() => {
            const unreadElement = document.getElementsByClassName("ab-unread-indicator");

            for (let i = 0; i < unreadElement.length; i++) {
                const elementClass = unreadElement[i].getAttribute('class').split(" ");

                unreadElement[i].parentElement.classList.add(...elementClass);
            }

        }, 500);

    }
}
