import { PLANS } from '../../../utils/variables';

export function getPlanTypeFromPlanId(planId) {
    if (!planId) return null;

    let planType = '';
    Object.keys(PLANS).forEach(frequency => {
        Object.keys(PLANS[frequency]).map(key => {
            if (PLANS[frequency][key] === planId.toString()) {
                planType = key;
            }
        });
    });

    return planType;
}

export function getPlanFrequencyFromPlanId(planId) {
    if (!planId) return null;

    let planFrequency = '';
    Object.keys(PLANS).forEach(frequency => {
        Object.keys(PLANS[frequency]).map(key => {
            if (PLANS[frequency][key] === planId) {
                planFrequency = frequency;
            }
        });
    });

    return planFrequency;
}

export function getUpgradePrices(nextBillDate, frequency, currentPlanAmount, newPlanAmount) {
    const daysRemaining = datediff(new Date(), new Date(nextBillDate));
    const planDays = frequency === 'yearly' ? 365 : 30;

    const unusedAmount = Math.round(currentPlanAmount * (daysRemaining / planDays));
    const planMonthlyPrice = Math.max(newPlanAmount - unusedAmount, 0);

    return {
        upgradePrice: frequency === 'yearly' ? planMonthlyPrice * 12 : planMonthlyPrice,
        unusedAmount: frequency === 'yearly' ? unusedAmount * 12 : unusedAmount
    };
}

function datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
}
