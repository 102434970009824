// static assets
export const PRIVATE_MOCKUP_ASSETS = 'https://api.smartmockups.com/mockupv2/assets/private';
// for testing
export const PUBLIC_MOCKUP_ASSETS = 'https://api.smartmockups.com/mockupv2/assets/public';

export const CUSTOM_MOCKUP_IMGIX = 'https://smartmockups-custom-mockup.imgix.net';
export const WEB_IMGIX = 'https://images-public.smartmockups.com';
export const WEB_VIDEO_IMGIX = 'https://smartmockups-web-assets.imgix.video';
export const PUBLIC_MOCKUP_IMGIX = `${WEB_IMGIX}/mockups`;
export const PUBLIC_MOCKUP_VIDEO_IMGIX = `${WEB_VIDEO_IMGIX}/mockups`;
export const MODELS_URL = 'https://d3ib3ojsu6nkli.cloudfront.net';
export const CUSTOM_MOCKUPS_MODELS_URL = 'https://d1fjyq4spyp9l5.cloudfront.net';

export const HISTORY_BUCKET = 'smartmockups-history';
export const HISTORY_IMGIX = `https://${HISTORY_BUCKET}.imgix.net`;

export const TEMP_UPLOADS_BUCKET = 'smartmockups-temp-uploads';
export const TEMP_UPLOADS_S3 = `https://${TEMP_UPLOADS_BUCKET}.s3.amazonaws.com`;
export const TEMP_UPLOADS_IMGIX = `https://${TEMP_UPLOADS_BUCKET}.imgix.net`;

// mockups API
export const MOCKUPS_API = 'https://api.smartmockups.com/mockupv2';

// bundles
export const BUNDLE_MOCKUPS_API = 'https://d1u0dtyjg0.execute-api.us-east-1.amazonaws.com/master';

// user API
export const USER_API_URL = 'https://api.smartmockups.com/userv1';

// services
export const SCREENSHOT_API = 'https://api.smartmockups.com/screenshotv1';
export const INTEGRATION_API = 'https://api.smartmockups.com/integrationsv1';
export const PRESET_API = 'https://api.smartmockups.com/presetv1';
export const BRANDING_API = 'https://api.smartmockups.com/brandingv1';
export const CUSTOM_MOCKUPS_API = 'https://api.smartmockups.com/custommockupsv1';
export const HISTORY_API = 'https://api.smartmockups.com/historyv1';
export const NOTIFICATION_API = 'https://api.smartmockups.com/notificationv1';
export const HOMEPAGE_API = 'https://api.smartmockups.com/homepagev1';
export const RENDERER_API = 'https://api.smartmockups.com/rendererv1';
export const USER_COLLECTIONS_API = 'https://api.smartmockups.com/usercollectionsv1';
export const BULK_EXPORT_API_WS = 'wss://cyiycil80k.execute-api.us-east-1.amazonaws.com/master';

export const VIDEO_MOCKUP_API_WS = 'wss://03pq3i8s1j.execute-api.us-east-1.amazonaws.com/master';
export const VIDEO_API_WS = 'wss://76bdag5sp8.execute-api.us-east-1.amazonaws.com/master';
export const USER_FILE_API = 'https://nrcu24dy8l.execute-api.us-east-1.amazonaws.com/master';
export const USER_FILE_API_WS = 'wss://e8zdblga15.execute-api.us-east-1.amazonaws.com/master';

export const NEXT_PUBLIC_CONTENTFUL_SPACE_ID = 'x1pmlwytqxoc';
export const NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN = 'b1tmnZmw6uHkSdh3gEcUfX1gEvDTI3LHCivkGUjLoLk';
export const NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN =
    'Dj2Aw3zbKlkgW7fSpsMxhEK1oaR4QX9mySl1CYi1IxU';

export const PLANS = {
    monthly: {
        pro: '527402',
        team5: '575845',
        team10: '575842',
        team15: '575838'
    },
    yearly: {
        pro: '527401',
        proBF: '744200',
        team5: '575844',
        team10: '575841',
        team15: '542357'
    }
};

export const PLAN_USER_DATA_UPDATES = {
    // pro monthly
    527402: {
        isTeamOwner: false,
        isPro: true
    },
    // pro yearly
    527401: {
        isTeamOwner: false,
        isPro: true
    },
    // pro Black friday
    744200: {
        isTeamOwner: false,
        isPro: true
    },
    // team 5 monthly
    575845: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 4 // prootže jedním z cílového počtu je i admin
    },
    // team 5 yearly
    575844: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 4
    },
    // team 10 monthly
    575842: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 9
    },
    // team 10 yearly
    575841: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 9
    },

    // team 15 monthly
    575838: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 14
    },
    // team 15 yearly
    542357: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 14
    },

    // legacy team 5 monthly
    542348: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 4 // prootže jedním z cílového počtu je i admin
    },
    // legacy team 5 yearly
    542349: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 4
    },
    // legacy team 10 monthly
    542353: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 9
    },
    // legacy team 10 yearly
    542354: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 9
    },

    // legacy team 15 monthly
    542356: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 14
    },

    // team legacy monthly
    52740: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 9
    },
    // team legacy yearly
    527403: {
        isTeamOwner: true,
        isPro: true,
        teamLimit: 9
    }
};
