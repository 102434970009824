import {
    SET_ACTIVE_HELPSCOUT
} from '../actions/actionTypes';


const INITIAL_STATE = {
    isActive: false
};

export default function helpscoutButtonReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_ACTIVE_HELPSCOUT:
            return {
                ...state,
                isActive: action.payload
            };
        default:
            return state;
    }
}
