import { getPlanFrequencyFromPlanId } from '../components/User/SubscriptionPlanPickerV2/SubscriptionPlansHelpers';
import { trackEvent } from './trackingParty';
import { isClient } from './envDetection';

export function optionalyTrackHelpscoutPageview() {
    /* eslint no-empty: "error" */
    try {
        if (isClient() && window.Beacon) {
            window.Beacon('event', {
                type: 'page-viewed',
                url: window.location.href,
                title: document.title
            });
        }
    } catch (err) {
        // continue regardless of error
    }
}

/**
 * Shows beacon message with given to user if he matches the targeted conditions
 * @param {String} messageId
 * @param {Object}  user
 * @param {String} targetPlan - plan type we're targeting with the message - "Free", "Pro", "Trial", "Team", "Premium"
 * @param {String} targetFrequency - subscription frequency - "monthly", "yearly"
 * @param {Number} minLength
 * @param {Number} maxLength
 */
export function showBeaconMessage({
    messageId,
    user,
    targetPlan,
    targetFrequency,
    minLength,
    maxLength
}) {
    if (!user || !messageId || !window.Beacon) return;

    const subscriptionData = getIdentifyData(user);

    if (
        subscriptionData.Subscription !== targetPlan ||
        subscriptionData.SubscriptionFrequency !== targetFrequency
    )
        return;

    if (
        minLength > subscriptionData.SubscriptionLength ||
        maxLength < subscriptionData.SubscriptionLength
    )
        return;

    window.Beacon('show-message', messageId);
    trackEvent('Helpscout message showed', { id: messageId });
}

// TODO: pricing_plans - should we still check against isPro?
export function getIdentifyData(user) {
    // get plan name
    let subscription = 'Free';
    if (user.isTrial) {
        subscription = 'Trial';
    } else if (user.isPro) {
        subscription = 'Pro';
    } else if (user.isTeamOwner || user.teams) {
        subscription = 'Team';
    } else if (user.isPremium) {
        subscription = 'Premium';
    }

    let name = '';
    if (user.given_name) {
        name = user.given_name;
    }

    if (user.family_name) {
        name += ` ${user.family_name}`;
    }

    return {
        name,
        email: user.email,
        Subscription: subscription,
        SubscriptionFrequency: getSubscriptionFrequency(user),
        SubscriptionLength: getSubscriptionLength(user)
    };
}
function getSubscriptionFrequency(user) {
    const res = getPlanFrequencyFromPlanId(user.planId);
    if (res) {
        return res;
    }
    return 'not-found';
}

function getSubscriptionLength(user) {
    if (!user.signupDate) return 'not-found';

    const now = new Date().getTime();
    const signupDate = new Date(user.signupDate).getTime();

    const diffDays = (now - signupDate) / (24 * 60 * 60 * 1000); // day to ms

    let diffMonths = diffDays / 30;
    if (diffMonths < 2) {
        diffMonths = roundNearQtr(diffMonths);
    } else if (diffMonths < 5) {
        diffMonths = roundNearhalf(diffMonths);
    } else {
        diffMonths = Math.floor(diffMonths);
    }
    return diffMonths;
}

function roundNearQtr(number) {
    return Math.round(number * 4) / 4;
}
function roundNearhalf(number) {
    return Math.round(number * 2) / 2;
}
