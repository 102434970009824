import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateUserSession as validateUserAction } from '../../../actions/authActions';
import ReactGA from 'react-ga';
import { setPremiumModal } from '../../../actions/windowActions';
import { trackEvent } from '../../../utils/trackingParty';
import { getUserLocalData } from '../../../apis/helpers';
import { AUTH_USER } from '../../../actions/actionTypes';
import useFederatedIdentityProviders from './useFederatedIdentityProviders';
import { isServer } from '../../../utils/envDetection';

const App = (props) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const auth = useSelector((state) => state.auth);
    const [isValidated, setIsValidated] = useState(false);
    const [isPreloaded, setIsPreloaded] = useState(false);

    // initialize federated identity providers hook
    useFederatedIdentityProviders();

    // pre-sign-in locally stored user
    useEffect(() => {
        const localUser = getUserLocalData();
        if (localUser) {
            dispatch({ type: AUTH_USER, payload: { user: localUser } });
        }
        setIsPreloaded(true);
    }, []);

    // validate user
    useEffect(
        () => {
            if (isPreloaded) {
                // console.log('App.didMount() props: ', props);
                dispatch(validateUserAction());
                setIsValidated(true);
            }
        },
        [props, isPreloaded]
    );

    useEffect(
        () => {
            if (isValidated === true && user) {
                // we need to show user the trial ending dialog, but this can't happen on reactivation page
                if (
                    user.isTrialEndModal &&
                    window.location.pathname.indexOf('reactivate-trial') === -1
                ) {
                    trackEvent('Trial end modal show');
                    dispatch(setPremiumModal('trial-ended', 'Trial end modal'));
                }

                if (user.isSingleAppUser) {
                    trackEvent('App user login');
                    dispatch(setPremiumModal('app-user'));
                }

                ReactGA.event({
                    category: 'User',
                    action: 'Logged in user returns'
                });
            }
        },
        [isValidated]
    );

    if (isServer()) {
        return props.children;
    }

    return auth.isUserLoaded ? props.children : null;
};

export default App;
