import React, { useEffect } from 'react';
import { wrapper } from '../store/store';
import LanguageProvider from '../containers/Common/LanguageProvider';
import '../styles/styles.global.scss';
import { initTrackingParty } from '../utils/trackingParty';
import Router from 'next/router';
import ReactGA from 'react-ga';
import NextNprogress from 'nextjs-progressbar';
import { optionalyTrackHelpscoutPageview } from '../utils/helpscoutBeaconHelpers';
import * as Sentry from '@sentry/node';
import { isOldIe } from '../utils/featureDetector';
import AuthProvider from '../containers/Common/AuthProvider';
import ErrorBoundary from '../containers/Common/ErrorBoundary';
import { initABTests } from '../utils/ABTest/ABTest';

// initialize Raven sentry.io error logging
if (
    process.env.NODE_ENV !== 'development' &&
    (typeof window === 'undefined' || !window.Cypress) &&
    process.env.NODE_ENV !== 'ci'
) {
    Sentry.init({
        dsn: 'https://efcad7c832af45379ada12c7c8cab251@o304254.ingest.sentry.io/5351755',
        release: `smartmockups-web-next@${process.env.GIT_SHA}`,
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            "'insertBefore' on 'Node'",
            'ServiceWorker',
            "'removeChild' on 'Node'",
            'Illegal invocation',
            'isTrusted',
            'Non-Error exception'
        ],
        beforeSend(event) {
            if (isOldIe()) {
                return null;
            }
            return event;
        },
        ignoreUrls: [/graph\.facebook\.com/, 'http://example.com/script2.js']
    });
}

// initialize lazysizes
// initial LQIP image replaced by full image once loaded
// src will be replaced by data-src/data-srcset
const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);
if (canUseDOM) {
    import('lazysizes');
    import('lazysizes/plugins/parent-fit/ls.parent-fit');
}

const WrappedApp = ({ Component, pageProps, err }) => {
    useEffect(() => {
        // initialise the Google analytics tracking
        ReactGA.initialize('UA-73135839-1');
        initTrackingParty();
        initABTests();
        const handleRouteChange = (url) => {
            ReactGA.set({ page: url });
            ReactGA.pageview(url);

            optionalyTrackHelpscoutPageview();
        };
        Router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    return (
        <ErrorBoundary>
            <AuthProvider>
                <LanguageProvider>
                    <>
                        <NextNprogress
                            color="#00BAFF"
                            startPosition={0.3}
                            stopDelayMs={200}
                            height="3"
                            options={{ showSpinner: false }}
                        />
                        <Component {...pageProps} err={err} />
                    </>
                </LanguageProvider>
            </AuthProvider>
        </ErrorBoundary>
    );
};

export default wrapper.withRedux(WrappedApp);
