/* eslint-disable func-names */
import Auth from '@aws-amplify/auth';
import {
    createUserObject,
    getUserPaymentsApi,
    login as loginApi,
    updateUserAttribute
} from '../apis/userApi';
import { v4 as uuidv4 } from 'uuid';
import {
    AUTH_ERROR,
    AUTH_NEW_PASSWORD_REQUIRED,
    AUTH_USER,
    CHANGE_ATTRIBUTE,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_CONFIRM,
    GET_USER_PAYMENTS,
    INIT_USER_PASSWORD,
    REGISTER_USER,
    RESET_STATE,
    SET_BRANDING,
    SET_USER,
    SET_USER_LOCALE,
    UNAUTH_USER,
    UNSET_FORGOT_PASSWORD,
    UPDATE_PRESET,
    AUTH_FEDERATED_SIGN_IN,
    VERIFY_USER_EMAIL,
    VERIFY_USER_EMAIL_SUCCESS,
    VERIFY_USER_EMAIL_ERROR,
    REGISTER_MFA,
    REGISTER_USER_CONFIRM,
    REGISTER_USER_ERROR,
    REQUEST_CHANGE_USER_EMAIL
} from './actionTypes';
import { saveTokenToLocalStorage, saveUserLocalData, setCookie } from '../apis/helpers';
import gravatar from 'gravatar';
import human from 'humanparser';
import { setTrackUserId, storeAmplitudeDeviceId, trackEvent } from '../utils/trackingParty';
import { brandingService, userService, presetService } from '../apis/ServiceClient';
import { getAuthCurrentUrl, setAuthCurrentUrl } from "../utils/authCurrentUrl";

const EXTERNAL_PROVIDERS = ['google', 'okta'];

export function authError(error) {
    return {
        type: AUTH_ERROR,
        payload: error
    };
}

// Cognito - Auth.signIn()
// Currently unused, handled via validateUserSession and userApi2.login
export function signIn(email, password) {
    return async function (dispatch) {
        console.log('actions.login(): username password:', { email, password });

        // signIn (cognito)
        try {
            Auth.configure({ authenticationFlowType: 'USER_SRP_AUTH' });
            const currentAuthUser = await Auth.signIn(email, password);

            // success -- dispatch AUTH_USER
            console.log('actions.login():Auth.signIn() data:', currentAuthUser);

            // inspect response 'data' and check whether
            // a] New Password is required (change 'FORCE_CHANGE_PASSWORD'
            //    to 'CONFIRMED'), dispatch-> AUTH_NEW_PASSWORD_REQUIRED with payload
            // b] otherwise, authenticate user, dispatch -> AUTH_USER
            if (currentAuthUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                dispatch({ type: AUTH_NEW_PASSWORD_REQUIRED, payload: currentAuthUser });
                return false;
            }

            // dispatch AUTH_USER
            const user = await authUserSession(currentAuthUser);
            if (user) {
                dispatch({ type: AUTH_USER, payload: { user, signInCallback: true } });
                return true;
            }
            dispatch(authError());
            return false;
        } catch (err) {
            console.error('actions.login():Auth.signIn() err:', { ...err });
            // error -- invoke authError which dispatches AUTH_ERROR
            dispatch(authError(err));
            return false;
        }
    };
}

// Cognito - Auth.currentAuthenticatedUser()
// Cognito - Auth.userSession()
// This is a pass-through function to indicate that user has already authenticated
// and has a valid Amplify session.
export function validateUserSession() {
    return async function (dispatch) {
        console.log('actions.validateUserSession()');
        try {
            const currentAuthUser = await Auth.currentAuthenticatedUser();
            console.log(
                'actions.validateUserSession():Auth.currentAuthenticatedUser() currentAuthUser:',
                currentAuthUser
            );

            // check if federatedSignUp goes on and run CUSTOM_AUTH to native account
            if (EXTERNAL_PROVIDERS.includes(currentAuthUser.username.split('_')[0].toLowerCase())) {
                console.log('%cFED SIGN IN', 'background-color: pink', currentAuthUser);
                dispatch({
                    type: AUTH_FEDERATED_SIGN_IN,
                    payload: { initialFederatedSignIn: true }
                });
                return;
            }

            const user = await authUserSession(currentAuthUser);
            // dispatch AUTH_USER
            if (user) {
                dispatch({ type: AUTH_USER, payload: { user } });
            } else {
                dispatch({ type: UNAUTH_USER });
            }
            getAuthCurrentUrl();
        } catch (err) {
            console.error('actions.validateUserSession(): Auth err:', err);
            // error occured during session validation, fire user is unauthenticated
            dispatch({ type: UNAUTH_USER });
        }
        // history.push('/');
    };
}

/**
 * After effect handler to operate the transition from federated external user signup
 * to a native cognito user via custom auth flow
 * 1] trigger the adminLinkProviderForUser flow to
 *   - link the Federated user to a native Cognito
 *   - and delete the Federated user
 * 2] clear the locally stored tokens
 * 3] do a custom auth flow with passwordless sign in to a native cognito user
 * @returns A promise resolves to current authenticated native CognitoUser if success
 */
export function handlePasswordlessSignIn() {
    return async function (dispatch) {
        let currentAuthUser = await Auth.currentAuthenticatedUser();

        // 1] link the external user to native cognito user
        const sourceUser = {
            username: currentAuthUser.username,
            email: currentAuthUser.attributes.email
        };
        const destinationUser = await userService.post(`linkuser`, { sourceUser });

        // 2] clear local stored tokens
        clearAuthLocalStorage(currentAuthUser);

        // 3] handle custom auth flow to native user
        const isVerified = await passwordlessSignIn(currentAuthUser, destinationUser);
        // check if challenge was successfully verified and user has been authed
        if (!isVerified) {
            dispatch({ type: UNAUTH_USER });
        }

        // prepare a user to dispatch AUTH_USER
        currentAuthUser = await Auth.currentAuthenticatedUser();
        const user = await authUserSession(currentAuthUser);
        // dispatch AUTH_USER
        if (user) {
            // TODO can be removed AFTER userSignUp service is updated
            setTrackUserId(user.username);

            trackEvent('User signup', { source: 'Federated sign-in' });
            dispatch({ type: AUTH_USER, payload: { user } });
        } else {
            dispatch({ type: UNAUTH_USER });
        }
    };
}

/**
 * clear local cached sign-in cookie and tokens
 * @param currentAuthUser
 */
const clearAuthLocalStorage = (currentAuthUser) => {
    // clear custom auth items
    localStorage.removeItem('user_token');
    localStorage.removeItem('user_token_expire');
    setCookie('userData', '', 30);
    // clear cognito auth items
    Object.entries(localStorage)
        .map((item) => item[0])
        .filter((item) => item.startsWith(currentAuthUser.keyPrefix))
        .forEach((item) => localStorage.removeItem(item));
};

/**
 * use the User Pool custom auth flow to sign-in the user
 * by doing a passwordless sign in with custom auth flow
 * @param destinationUser - a native Cognito user to be signed in to
 * @returns  - A promise resolves to current authenticated native CognitoUser if success
 */
const passwordlessSignIn = async (currentAuthUser, destinationUser) => {
    const session = await Auth.userSession(currentAuthUser);
    if (!session.isValid()) {
        return;
    }
    // set the federated user's token as the challenge response
    const challengeResponse = session.getIdToken().getJwtToken();
    // custom auth flow to sing in native Cognito Provider user instead of Federated user
    Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
    const user = await Auth.signIn(destinationUser.Username);
    if (user.challengeName === 'CUSTOM_CHALLENGE') {
        // to send the answer of the custom challenge
        const response = await Auth.sendCustomChallengeAnswer(user, challengeResponse);
        // check if the user from challenge response has been verified
        if (response.signInUserSession) {
            return true;
        }
        return false;
    }
    return false;
};

// trigger federated signIn action
export async function federatedSignIn({ provider }) {
    // so we can merge the anonymous events in callback
    storeAmplitudeDeviceId();
    setAuthCurrentUrl();

    await Auth.federatedSignIn({ provider });
}

// This function handles dispatch AUTH_USER with constructed user object
// Shared by signIn and validateUserSession actions
async function authUserSession(currentAuthUser, cache = true) {
    // grab the user session
    const session = await Auth.userSession(currentAuthUser);
    console.log('actions.validateUserSession():Auth.userSession() session:', session);

    // finally invoke isValid() method on session to check if auth tokens are valid
    // if tokens have expired, lets call "logout"
    // otherwise, dispatch AUTH_USER success action and by-pass login
    if (session.isValid()) {
        // fire user is authenticated
        const user = await createUserObject(currentAuthUser);
        if (cache) {
            saveTokenToLocalStorage(session.getIdToken().getJwtToken());
            saveUserLocalData(user);
        }
        return user;
    }
    // fire user is unauthenticated
    return false;
}

// Cognito - Auth.completeNewPassword()
export function setNewPassword(cognitoUser, newPassword, onLoginSuccess) {
    return async function (dispatch) {
        console.log('actions.setNewPassword(): cognitoUSer, newPassword:', {
            cognitoUser,
            newPassword
        });

        // completeNewPassword (cognito)
        try {
            const data = await Auth.completeNewPassword(cognitoUser, newPassword);
            console.log('actions.setNewPassword():Auth.completeNewPassword() data: ', data);

            // dispatch AUTH_USER
            const currentAuthUser = await Auth.currentAuthenticatedUser();
            const user = await createUserObject(currentAuthUser);
            const auth = { user };

            dispatch({ type: AUTH_USER, payload: auth });

            const jwtToken = cognitoUser.getSignInUserSession().getIdToken().getJwtToken();
            saveTokenToLocalStorage(jwtToken);

            // we have authenticated, lets navigate to final route
            onLoginSuccess(auth);
        } catch (err) {
            console.error('actions.setNewPassword():Auth.completeNewPassword() err:', err);
            // error -- invoke authError which dispatches AUTH_ERROR
            dispatch(authError(err));
        }
    };
}

// Cognito - Auth.signOut()
export function logout() {
    return async function (dispatch) {
        console.log('actions.logout()');

        // signOut (cognito)
        try {
            const data = await Auth.signOut();
            console.log('actions.logout():Auth.signOut() data:', data);

            dispatch({ type: UNAUTH_USER });

            localStorage.clear();
            setCookie('userData', '', 30);
            // we have unauthenticated, lets navigate to /main route
            // history.push('/');
        } catch (err) {
            console.error('actions.logout():Auth.signOut() err:', err);
            // error -- invoke authError which dispatches AUTH_ERROR
            dispatch(authError(err));
        }
    };
}

// Cognito - Auth.signUp()
export function signUp(email, name, password) {
    return async function (dispatch) {
        console.log('actions.register(): name, password, email, password: ', {
            name,
            email,
            password
        });

        const username = uuidv4();

        // get gravatar image or set default
        const gravatarData = {
            size: 100,
            default: 'mm'
        };
        const profileImage = gravatar.url(email, gravatarData);

        // prepare attributes
        const attributeList = {
            email,
            picture: profileImage
        };

        if (name) {
            const nameObject = human.parseName(name);
            attributeList.given_name = nameObject.firstName ? nameObject.firstName : '';
            attributeList.family_name = nameObject.lastName ? nameObject.lastName : '';
        }

        // signUp (cognito)
        let data;
        try {
            data = await Auth.signUp({
                username,
                password,
                attributes: attributeList
            });
            console.log('actions.register():Auth.signUp() data:', data);
        } catch (err) {
            console.error('actions.register():Auth.signUp() err:', err);
            // error -- invoke authError which dispatches REGISTER_USER_ERROR
            dispatch(authError(err));
            return err;
        }

        // MFA is required for user registration
        if (typeof data.userConfirmed !== 'undefined' && data.userConfirmed === false) {
            dispatch({ type: REGISTER_MFA, payload: data });
            return data;
        }
        // user registration successful
        dispatch({ type: REGISTER_USER, payload: data });
        try {
            await loginApi(email, password);
            return data;
        } catch (err) {
            trackEvent('Signup error', { code: err.code, message: err.message });
            console.log(`Signup error: ${err.code} ${err.message}`);
            return err;
        }
    };
}

// Request an email change - will handle sending the verification code onto new email address
// and keep the email in current value in account meanwhile
export function changeEmailRequest(newEmail) {
    return async function (dispatch) {
        try {
            const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
            const user = await Auth.currentAuthenticatedUser();

            if (user.attributes.email === newEmail) {
                throw new Error(`${newEmail} is already your email address`);
            }
            const accessToken = currentAuthenticatedUser.signInUserSession.accessToken.jwtToken;

            const response = await userService.post(`change-email-request`, {
                newEmail,
                accessToken
            });
            console.log('actions.changeEmailRequest() response:', response);
            dispatch({ type: REQUEST_CHANGE_USER_EMAIL, payload: { newEmail, response } });
        } catch (err) {
            console.error('actions.changeEmailRequest() err:', err);
            // error -- invoke authError which dispatches AUTH_ERROR
            dispatch(authError(err));
        }
    };
}

// Called to confirm the email change by validating verification code that has been sent to the new email
export function changeEmailConfirm(code, newEmail) {
    return async function (dispatch) {
        try {
            const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
            const accessToken = currentAuthenticatedUser.signInUserSession.accessToken.jwtToken;

            const response = await userService.post(`change-email-confirm`, {
                code,
                newEmail,
                accessToken
            });
            console.log('actions.changeEmailConfirm() response:', response);
            await dispatch({
                type: VERIFY_USER_EMAIL_SUCCESS,
                payload: { email: newEmail, response }
            });
            // this will refresh the user with bypassing cache so the email will be changed upon page refresh
            await Auth.currentAuthenticatedUser({ bypassCache: true });
        } catch (error) {
            console.log('actions.changeEmailConfirm():changeUserEmail() err:', {
                ...error
            });
            dispatch({
                type: VERIFY_USER_EMAIL_ERROR,
                payload: { error }
            });
        }
    };
}

// Cognito - Auth.verifyUserAttribute()
export function verifyUserEmail() {
    return async function (dispatch) {
        try {
            // verifyCurrentUserAttribute (cognito)
            const data = await Auth.verifyCurrentUserAttribute('email');
            console.log('actions.verifyUserAttribute():Auth.verifyUserAttribute() data:', data);
            dispatch({ type: VERIFY_USER_EMAIL });
        } catch (err) {
            console.error('actions.verifyUserAttribute():Auth.verifyUserAttribute() err:', err);
            // error -- invoke authError which dispatches AUTH_ERROR
            dispatch(authError(err));
        }
    };
}

// Cognito - Auth.confirmSignUp()
export function confirmRegistration({ cognitoUser, code }) {
    return async function (dispatch) {
        console.log('actions.confirmRegistration(): cognitoUSer, code:', { cognitoUser, code });
        const { username } = cognitoUser.user;

        // confirmSignUp (cognito)
        try {
            const data = await Auth.confirmSignUp(username, code);
            dispatch({ type: REGISTER_USER_CONFIRM });
            console.log('actions.confirmRegistration():Auth.confirmSignUp() data: ', data);
            return true;
        } catch (err) {
            console.error('actions.confirmRegistration():Auth.confirmSignUp() err:', err);

            // error -- invoke authError which dispatches AUTH_ERROR
            // dispatch(authError(err));
            dispatch({ type: REGISTER_USER_ERROR, payload: err.message, cognitoUser });
            return false;
        }
    };
}
// Cognito - Auth.resendSignUp()
export function resendConfirmationCode({ cognitoUser }) {
    return function (dispatch) {
        console.log('actions.resendConfirmationCode(): username: ', { cognitoUser });
        const { username } = cognitoUser.user;

        // resendSignUp (cognito)
        Auth.resendSignUp(username)
            .then((data) => {
                console.log('actions.resendConfirmationCode():Auth.resendSignUp() data:', data);

                dispatch({ type: REGISTER_MFA, payload: cognitoUser });
            })
            .catch((err) => {
                console.error(
                    'actions.confirmForgotPassword():Auth.forgotPasswordSubmit() err:',
                    err
                );

                // error -- invoke authError which dispatches AUTH_ERROR
                dispatch(authError(err));
            });
    };
}

// Cognito - Auth.forgotPassword()
export function forgotPassword(username) {
    return async function (dispatch) {
        console.log('actions.forgotPassword(): username: ', username);
        try {
            // forgotPassword (cognito)
            const data = await Auth.forgotPassword(username);
            console.log('actions.forgotPassword():Auth.forgotPassword() data:', data);
            dispatch({ type: FORGOT_PASSWORD, username });
        } catch (err) {
            console.error('actions.forgotPassword():Auth.forgotPassword() err:', err);
            // error -- invoke authError which dispatches AUTH_ERROR
            dispatch(authError(err));
        }
    };
}

// Cognito - Auth.forgotPasswordSubmit()
export function confirmForgotPassword(username, code, newPassword, history, auth) {
    return async function (dispatch) {
        console.log('actions.confirmForgotPassword(): username, code, newPassword: ', {
            username,
            code,
            newPassword,
            history,
            auth
        });

        // forgotPasswordSubmit (cognito)
        try {
            const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
            console.log('actions.confirmForgotPassword():Auth.forgotPasswordSubmit() data:', data);

            // TODO - User password changed successfully, do we need to login again?
            dispatch({ type: FORGOT_PASSWORD_CONFIRM });

            // check if the flow is coming from initially federated account password set
            // then we should be in personal settings and dont want to redirect anywhere
            if (auth.user && auth.user.isPasswordSet === false) {
                const currentAuthUser = await Auth.currentAuthenticatedUser();
                dispatch(initUserPassword(currentAuthUser.username, true));
                return true;
            }

            history.push('/login?open=password-success');
        } catch (err) {
            console.error('actions.confirmForgotPassword():Auth.forgotPasswordSubmit() err:', err);

            // error -- invoke authError which dispatches AUTH_ERROR
            dispatch(authError(err));
        }
    };
}

// Cognito - unset Auth.forgotPassword()
export function unsetForgotPassword(username) {
    return function (dispatch) {
        console.log('Unset actions.forgotPassword(): username: ', username);
        dispatch({ type: UNSET_FORGOT_PASSWORD, username });
    };
}

/// //////////////////
// USER DATA ACTIONS
/// //////////////////
// Used to update auth.user data
export function setUser(user) {
    return {
        type: SET_USER,
        payload: user
    };
}

// Used in User Settings => Branding
export function addBranding(data, username) {
    const request = brandingService.post(`branding/${username}`, data);
    return {
        type: SET_BRANDING,
        payload: request
    };
}

export function deleteWatermark(index, username) {
    const request = brandingService.delete(`watermarks/${username}/${index}`);
    return {
        type: SET_BRANDING,
        payload: request
    };
}

export function duplicateWatermark(index, username) {
    const request = brandingService.post(`watermarks/${username}/copy`, {
        from: index
    });
    return {
        type: SET_BRANDING,
        payload: request
    };
}

// Used in User Settings => Plans
export function getUserPayments() {
    const request = getUserPaymentsApi();
    return {
        type: GET_USER_PAYMENTS,
        payload: request
    };
}

// used in User Settings => Personal
export function changeUserAttribute(name, value, isCustom) {
    const request = changeAttributeAsync(name, value, isCustom);
    return {
        type: CHANGE_ATTRIBUTE,
        payload: request
    };
}

function changeAttributeAsync(name, value, isCustom) {
    return new Promise((resolve) => {
        updateUserAttribute(name, value, isCustom)
            .then(() => {
                resolve({ name, value });
            })
            .catch((err) => {
                console.log(err);
                // if attribute fails to update remotely, update at least local isntance
                // this is useful for trial users
                resolve({ name, value });
            });
    });
}

// reset whole state to initial values
export function clear() {
    return {
        type: RESET_STATE
    };
}

export function addPreset(data) {
    const request = presetService.post(`preset`, data);
    return {
        type: UPDATE_PRESET,
        payload: request
    };
}

export function deletePreset(id) {
    const request = presetService.delete(`preset/${id}`);
    return {
        type: UPDATE_PRESET,
        payload: request
    };
}

export function setUserLocale(username, locale) {
    const request = userService.post(`locale/${username}`, { locale });
    return {
        type: SET_USER_LOCALE,
        payload: request
    };
}

export function initUserPassword(username, state) {
    const request = userService.post(`init-password/${username}`, { state });
    return {
        type: INIT_USER_PASSWORD,
        payload: request
    };
}
